import React from "react";

const NotFound = () => (
  <div>
    <h2>404. Not found.</h2>
    <p>
      <a href="/">Go to home page</a>
    </p>
  </div>
);

export default NotFound;
