export default {
  xs: {
    min: 0,
    max: 733
  },
  sm: {
    min: 734,
    max: 991
  },
  md: {
    min: 992,
    max: 1199
  },
  lg: {
    min: 1200,
    max: 1000000
  }
};
